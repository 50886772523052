import {
  ApiErrorResponse,
  CreateExternalSessionResponse,
  ExternalSession,
  GetCsrfTokenResponse,
  GetExternalSessionsResponse,
  UpdateExternalSessionResponse,
} from "@amzn/it-support-connect-api-model";
import sentryFetch from "@amzn/sentry-fetch";

import { logger } from "../../logger";
import {
  CONNECT_API_BETA_URL,
  CONNECT_API_PROD_URL,
  CONNECT_BETA_URL,
  CONNECT_DEV_URL,
  CONNECT_PROD_URL,
  OMNIA_BETA_URL,
  OMNIA_DEV_URL,
  OMNIA_GAMMA_URL,
  OMNIA_PROD_URL,
} from "../config";

// NOTE: Update the alias in the URL below to point to yours and also deploy ITSC Api pipeline to ADA in order to
// test external sessions feature end to end locally.
let URL: string;
if (
  window.location.hostname === OMNIA_DEV_URL ||
  window.location.hostname === CONNECT_DEV_URL
) {
  URL = "https://api.connect.ektaagga.aka.it.a2z.com";
} else if (
  window.location.hostname === OMNIA_BETA_URL ||
  window.location.hostname === CONNECT_BETA_URL
) {
  URL = CONNECT_API_BETA_URL;
} else if (
  window.location.hostname === OMNIA_GAMMA_URL ||
  window.location.hostname === OMNIA_PROD_URL ||
  window.location.hostname === CONNECT_PROD_URL
) {
  URL = CONNECT_API_PROD_URL;
}

/* Fetch CSRF token for the user
 * @param csrfData Data used to create a unique csrf token
 */
async function fetchCsrfToken(csrfData: string): Promise<GetCsrfTokenResponse> {
  const response = await sentryFetch(`${URL}/midway/csrf-token`, {
    method: "GET",
    credentials: "include",
    headers: {
      "x-csrf-data": csrfData,
    },
  });
  const json = (await response.json()) as unknown;
  if (response.ok) {
    return json as GetCsrfTokenResponse;
  } else {
    logger.info(`Failed to fetch CSRF token due to ${JSON.stringify(json)}`);
    throw new Error(JSON.stringify(json));
  }
}

/* Fetch existing external sessions for a particular Amazon Connect contact
  @param contactId Amazon contact Id of the customer
*/
export async function fetchExternalSessions(
  contactId: string,
  originalContactId: string
): Promise<ExternalSession[]> {
  // get external sessions
  const response = await sentryFetch(
    `${URL}/midway/contacts/${contactId}/external-sessions`,
    {
      method: "GET",
      credentials: "include",
      headers: {},
    }
  );
  const json = (await response.json()) as unknown;
  if (response.ok) {
    return (json as GetExternalSessionsResponse).externalSessions;
  } else {
    logger.info(
      `Failed to fetch external sessions for contactID: ${contactId}`
    );
    throw new Error(JSON.stringify(json));
  }
}

/* This function starts an external session.
  @param contactId Amazon contact Id of the customer
*/
export async function startSession(
  contactId: string
): Promise<ExternalSession> {
  const body = JSON.stringify({});
  const path = `/midway/contacts/${contactId}/external-sessions`;
  const httpMethod = "POST";
  const csrfData = `${path}-${httpMethod}-${body}`;
  const csrfToken = await fetchCsrfToken(csrfData);

  // create external session
  const response: Response = await sentryFetch(`${URL}${path}`, {
    method: httpMethod,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": csrfToken.token,
    },
    body: body,
  });
  const json = (await response.json()) as unknown;
  if (response.ok) {
    return (json as CreateExternalSessionResponse).externalSession;
  } else {
    if (response.status === 400) {
      if ((json as ApiErrorResponse).code === "AGENT_NOT_LOGGED_IN_BOMGAR") {
        logger.info(`The calling agent is not logged into bomgar.`);
        throw new Error(
          "Agent must be logged into Bomgar to create an external session"
        );
      } else if ((json as ApiErrorResponse).code === "CONTACT_NOT_VERIFIED") {
        logger.info(
          `No verified verification id found for contact: ${contactId}`
        );
        throw new Error("Please ensure that the customer is RIV verified");
      } else {
        // Should not get to here. This API only returns 400 under above conditions.
        logger.info(`Failed to start a session for contact: ${contactId}`);
        throw new Error("Failed to start a session");
      }
    } else {
      logger.info(`Failed to start a session for contact: ${contactId}`);
      throw new Error("Failed to start a session");
    }
  }
}

/* This function ends an active external session.
  @param contactId Amazon Connect contact Id of the customer
  @param startedTimestamp Time at which the session was started
*/
export async function endSession(
  contactId: string,
  startedTimestamp: string,
  originalContactId: string
): Promise<ExternalSession> {
  const body = JSON.stringify({
    status: "ENDED",
  });
  const path = `/midway/contacts/${contactId}/external-sessions/${startedTimestamp}`;
  const httpMethod = "PATCH";
  const csrfData = `${path}-${httpMethod}-${body}`;
  const csrfToken = await fetchCsrfToken(csrfData);

  // end external session
  const response = await sentryFetch(`${URL}${path}`, {
    method: httpMethod,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": csrfToken.token,
    },
    body: body,
  });
  const json = (await response.json()) as unknown;
  if (response.ok) {
    return (json as UpdateExternalSessionResponse).externalSession;
  } else {
    logger.info(
      `Failed to end a session for contactId: ${contactId} startedTimestamp: ${startedTimestamp}`
    );
    throw new Error(JSON.stringify(json));
  }
}
