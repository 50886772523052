import "./style.scss";

import {
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import { ExternalSession } from "@amzn/it-support-connect-api-model";
import React, { useEffect, useState } from "react";

import { useSelector } from "../../state/hooks";
import { SystemMessage } from "../types";
import { endSession, fetchExternalSessions, startSession } from "./api";

export interface ContactInfo {
  readonly contactId: string;
  readonly originalContactId: string;
  readonly contactType: connect.ContactType;
}

interface ExternalSessionPanelProps {
  readonly contactInfo: ContactInfo;
  readonly sendMessage: (
    contactId: string,
    message: string,
    contentType: connect.ChatMessageContentType
  ) => void;
}

enum LoadStatus {
  Loaded,
  Loading,
  Error,
}

export const ExternalSessionPanel: React.FC<ExternalSessionPanelProps> = (
  props
) => {
  // Local State
  const [expanded, setExpanded] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [activeSession, setActiveSession] = useState<ExternalSession>();
  const [channelInCreation, setChannelInCreation] = useState<boolean>(false);
  const [terminationInProgress, setTerminationInProgress] = useState<boolean>(
    false
  );
  const [loadStatus, setLoadStatus] = useState<LoadStatus>(LoadStatus.Loaded);

  // expand or collapse the controller
  const toggleControllerExpansion = (): void => {
    setExpanded(!expanded);
  };

  // fetch existing external sessions on page load
  const getExternalSessions = async (): Promise<void> => {
    setLoadStatus(LoadStatus.Loading);
    setExpanded(false);
    try {
      const externalSessions = await fetchExternalSessions(
        props.contactInfo.contactId,
        props.contactInfo.originalContactId
      );
      if (externalSessions.length > 0) {
        setActiveSession(externalSessions[0]);
        if (externalSessions[0].status === "SESSION_URL_GENERATED") {
          setSuccessMessage(
            getSessionKeyUrlSuccessMessage(externalSessions[0])
          );
        } else if (externalSessions[0].status === "STARTED") {
          setSuccessMessage("Remote session in progress");
        }
      } else {
        setActiveSession(undefined);
      }

      setLoadStatus(LoadStatus.Loaded);
    } catch (error) {
      setLoadStatus(LoadStatus.Error);
    }
  };

  // generate the session key generation message based on the type of contact
  const getSessionKeyUrlSuccessMessage = (session: ExternalSession): string => {
    const localExpiryTime = new Date(
      session.externalSessionKeyExpiry!
    ).toLocaleTimeString();

    if (props.contactInfo.contactType === connect.ContactType.CHAT) {
      return `Successfully generated session key: ${session.externalSessionKeyUrl!} \n\n The session key has been sent to the customer. It is valid until ${localExpiryTime}`;
    } else if (props.contactInfo.contactType === connect.ContactType.VOICE) {
      return `Successfully generated session key: ${session.externalSessionKeyUrl!} \n\n Please send it to the customer. It is valid until ${localExpiryTime}`;
    }
    return `Successfully generated session key: ${session.externalSessionKeyUrl!}`;
  };

  const startExternalSession = async (): Promise<void> => {
    setChannelInCreation(true);
    setErrorMessage(undefined);
    setSuccessMessage(undefined);
    try {
      const session: ExternalSession = await startSession(
        props.contactInfo.contactId
      );
      setActiveSession({ ...session });
      const systemMessage: SystemMessage = {
        type: "EXTERNAL_SESSION_START",
        content: session.externalSessionKeyUrl,
      };
      props.sendMessage(
        props.contactInfo.contactId,
        JSON.stringify(systemMessage),
        "application/json"
      );
      setSuccessMessage(getSessionKeyUrlSuccessMessage(session));
    } catch (err) {
      const error = err as Error;
      setErrorMessage(error.message);
    }
    setChannelInCreation(false);
  };

  const endExternalSession = async (): Promise<void> => {
    if (activeSession) {
      setTerminationInProgress(true);
      setSuccessMessage(undefined);
      setErrorMessage(undefined);
      try {
        await endSession(
          props.contactInfo.contactId,
          activeSession.startedTimestamp,
          props.contactInfo.originalContactId
        );
        const systemMessage: SystemMessage = {
          type: "EXTERNAL_SESSION_END",
        };
        props.sendMessage(
          props.contactInfo.contactId,
          JSON.stringify(systemMessage),
          "application/json"
        );
        setActiveSession(undefined);
        setSuccessMessage("Successfully ended active session");
      } catch (err) {
        setErrorMessage(`Failed to end the active session.`);
      }
      setTerminationInProgress(false);
    }
  };

  // ends an external session
  const endActiveExternalSession = (): void => {
    void endExternalSession();
  };

  // fetch external sessions when the component loads
  // note: when popped out, ccp creates a new window and sets contact id, which runs the following as well.
  useEffect(() => {
    void getExternalSessions();
  }, [props.contactInfo.contactId]);

  // fetch external session when ccp is popped in only
  const isCcpPoppedOutOfWindow = useSelector((state) => state.ccpInPopup);
  useEffect(() => {
    if (!isCcpPoppedOutOfWindow) {
      void getExternalSessions();
    }
  }, [isCcpPoppedOutOfWindow]);

  return (
    <div className={expanded ? "expanded-container" : "collapsed-container"}>
      {loadStatus === LoadStatus.Loading && (
        <div className="title-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <div className="spinner">
              <Spinner variant="inverted"></Spinner>
            </div>
            <div className="container-title">Loading external sessions...</div>
          </SpaceBetween>
        </div>
      )}
      {loadStatus === LoadStatus.Error && (
        <div className="title-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <div className="error-icon">
              <StatusIndicator type="error"></StatusIndicator>
            </div>
            <div className="container-title">
              Failed to load external sessions.
            </div>
            <Button
              iconName="refresh"
              variant="icon"
              className="awsui-visual-refresh awsui-polaris-dark-mode retry"
              onClick={() => void getExternalSessions()}
            />
          </SpaceBetween>
        </div>
      )}
      {loadStatus === LoadStatus.Loaded && (
        <div className="title-container" onClick={toggleControllerExpansion}>
          <SpaceBetween direction="horizontal" size="xxs">
            <Button
              className="awsui-visual-refresh awsui-polaris-dark-mode"
              iconName={expanded ? "caret-down-filled" : "caret-right-filled"}
              variant="icon"
            />
            <div className="container-title">
              {activeSession ? "End Session" : "Generate Session Key"}
            </div>
          </SpaceBetween>
        </div>
      )}
      {expanded && (
        <div className="button-container">
          {activeSession && (
            <Button
              onClick={endActiveExternalSession}
              className="awsui-visual-refresh awsui-polaris-dark-mode end-session-btn"
              disabled={terminationInProgress}
              loading={terminationInProgress}
            >
              End Session
            </Button>
          )}
          {!activeSession && (
            <div>
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => void startExternalSession()}
                  className={`awsui-visual-refresh awsui-polaris-dark-mode ${"BOMGAR".toLowerCase()}-btn`}
                  disabled={channelInCreation}
                  loading={channelInCreation}
                  key={"BOMGAR"}
                >
                  Generate Session Key
                </Button>
              </SpaceBetween>
            </div>
          )}
        </div>
      )}
      {successMessage && expanded && (
        <div className="message-container">
          <StatusIndicator type="success"></StatusIndicator>
          <div className="success-message">{successMessage}</div>
        </div>
      )}
      {errorMessage && expanded && (
        <div className="message-container">
          <StatusIndicator type="error"></StatusIndicator>
          <div className="error-message">{errorMessage}</div>
        </div>
      )}
    </div>
  );
};
