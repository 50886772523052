import React, { useContext, useEffect, useRef, useState } from "react";

import { logger } from "../../logger";
import { AppStateContext } from "../../state/provider";
import { useMounted } from "../hooks/common";
import { endSession, fetchExternalSessions } from "./api";
import { ContactInfo, ExternalSessionPanel } from "./ExternalSessionPanel";

export const ExternalSessionControl: React.FC<unknown> = () => {
  const [contactInfo, setContactInfo] = useState<ContactInfo>();
  const refCurrentViewedContact = useRef<string>(); // This is use to keep track of the current contact on the screen.
  const appState = useContext(AppStateContext);
  const mounted = useMounted();

  useEffect(() => {
    if (appState.ccpInitialized) {
      connect.agent((agent) => addConnectEventListeners(agent));
    }
  }, [appState.ccpInitialized]);

  /**
   * This function returns the contact with the specified contactId and allowed states, or undefined when no such a contact exists.
   * @param agent the agent object.
   * @param contactId the contactId in Amazon Connect.
   * @param allowedStates only return the contact if it is in one of these states.
   * @param contactType optional type to filter out particular type of contacts (chat, voice etc)
   * @returns contact
   */
  const findContact = (
    agent: connect.Agent,
    contactId: string,
    allowedStates: connect.ContactStateType[],
    contactTypes: connect.ContactType[]
  ): connect.Contact | undefined => {
    return agent
      .getContacts()
      .find(
        (contact: connect.Contact) =>
          contact.contactId === contactId &&
          allowedStates.includes(contact.getState().type) &&
          contactTypes.includes(contact.getType())
      );
  };

  /**
   * This function sends a message to the specified contact via Amazon Connect.
   * @param contactId the contactId in Amazon Connect.
   * @param message the message to be sent.
   * @param contentType the content type of the message.
   */
  const sendMessage = (
    contactId: string,
    message: string,
    contentType: connect.ChatMessageContentType
  ): void => {
    if (appState.ccpInitialized) {
      connect.agent((agent) => {
        void (async () => {
          try {
            const contact = findContact(
              agent,
              contactId,
              [connect.ContactStateType.CONNECTED],
              [connect.ContactType.CHAT]
            );
            if (contact) {
              const agentConnection = contact.getAgentConnection();
              if (agentConnection instanceof connect.ChatConnection) {
                const chatSession = (await agentConnection.getMediaController()) as connect.ChatSession;
                await chatSession.sendMessage({
                  message: message,
                  contentType: contentType,
                });
              } else {
                logger.error("Connection is not a chat connection.");
              }
            } else {
              logger.error(`Contact ${contactId} is not a connected contact.`);
            }
          } catch (error) {
            logger.error("Failed to send message:", error);
          }
        })();
      });
    } else {
      logger.error("Failed to send message: CCP not initialized.");
    }
  };

  const isExternalSessionSupported = (contact: connect.Contact) => {
    return contact.getAttributes()["sourceApp"]?.value !== "ASSIST";
  };

  // add connect event listeners
  const addConnectEventListeners = (agent: connect.Agent): void => {
    connect.core.onViewContact((viewedContact) => {
      if (mounted.current) {
        // update the current contact on the screen.
        refCurrentViewedContact.current = viewedContact.contactId;
        // If agent has switched to a chat or voice contact that is either connected or ended (ACW),
        // show the panel for that chat contact.
        // If agent has switched to a contact that is not chat or voice, or a chat/voice not being connected or ended,
        // hide the panel.
        const contact = findContact(
          agent,
          viewedContact.contactId,
          [connect.ContactStateType.CONNECTED, connect.ContactStateType.ENDED],
          [connect.ContactType.CHAT, connect.ContactType.VOICE]
        );
        if (contact && isExternalSessionSupported(contact)) {
          setContactInfo({
            contactId: contact.contactId,
            originalContactId: contact.getOriginalContactId(),
            contactType: contact.getType(),
          });
        } else {
          setContactInfo(undefined);
        }
      }
    });

    connect.contact((contact) => {
      // When a chat or voice contact is connected, show the panel.
      // Otherwise, hide the panel.
      contact.onConnected((connectedContact) => {
        if (mounted.current) {
          // The onConnected event should have an effect,
          // only when it is for the current viewed contact.
          if (connectedContact.contactId === refCurrentViewedContact.current) {
            if (
              (connectedContact.getType() === connect.ContactType.CHAT ||
                connectedContact.getType() === connect.ContactType.VOICE) &&
              isExternalSessionSupported(connectedContact)
            ) {
              setContactInfo({
                contactId: connectedContact.contactId,
                originalContactId: connectedContact.getOriginalContactId(),
                contactType: connectedContact.getType(),
              });
            } else {
              setContactInfo(undefined);
            }
          }
        }
      });

      // When the panel is showing external sessions for the contact being destroy,
      // hide it.
      contact.onDestroy((destroyedContact) => {
        if (mounted.current) {
          setContactInfo((currentContactInfo) =>
            destroyedContact.contactId === currentContactInfo?.contactId
              ? undefined
              : currentContactInfo
          );
          // if there's any active session, end it before closing the contact
          void (async () => {
            try {
              if (
                (destroyedContact.getType() === connect.ContactType.CHAT ||
                  destroyedContact.getType() === connect.ContactType.VOICE) &&
                destroyedContact.getState().type ===
                  connect.ContactStateType.ENDED &&
                isExternalSessionSupported(destroyedContact)
              ) {
                const session = await fetchExternalSessions(
                  destroyedContact.contactId,
                  destroyedContact.getOriginalContactId()
                );
                if (session.length > 0) {
                  await endSession(
                    destroyedContact.contactId,
                    session[0].startedTimestamp,
                    destroyedContact.getOriginalContactId()
                  );
                }
              }
            } catch (error) {
              logger.info("Failed to end the session when closing contact");
            }
          })();
        }
      });
    });
  };

  return contactInfo ? (
    <ExternalSessionPanel
      contactInfo={contactInfo}
      sendMessage={sendMessage}
      key={contactInfo.contactId} // The key makes sure each contact gets its own ExternalSessionPanel instance. This is to protect against edge cases.
    ></ExternalSessionPanel>
  ) : (
    <></>
  );
};
